<template>
  <v-container>
    <v-card class="mx-auto px-5" outlined color="transparent">
      <div class="mt-10 text--primary text-h5">
        <span>Alliance</span>
      </div>
      <v-divider></v-divider>
      <br />
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          :cols="card.flex"
          sm="12"
          md="6"
          lg="4"
          xl="3"
        >
          <a :href="card.url" target="_blnak" class="text-decoration-none">
            <v-hover>
              <v-card class="mx-auto py-10 px-3 hoverImages" max-width="400" height="400">
                <v-img :src="card.src" height="200px" contain></v-img>

                <v-divider class="mt-5"></v-divider>

                <div>
                  <v-card-title class="text-caption text-sm-subtitle-1">
                    <b>
                      {{ card.title }}
                    </b>
                  </v-card-title>

                  <v-card-subtitle class="text-caption text-sm-subtitle-1">
                    <p>
                      {{ card.url }}
                    </p>
                  </v-card-subtitle>
                </div>

                <v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>
                      {{ card.detail }}
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card></v-hover
            >
          </a>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    show: false,
    cards: [
      {
        title: 'Affirming United',
        url: 'https://affirmunited.ause.ca/',
        src:
          'https://affirmunited.ause.ca/wp-content/uploads/sites/2/2020/06/cropped-cropped-AUSELogo-transparentBground-1.jpg',
        flex: 12,
      },
      {
        title: 'UCC',
        url: 'https://united-church.ca/',
        src:
          'https://united-church.ca/themes/custom/businessplus/crest-web-logo-navy.svg',
        flex: 12,
      },
      {
        title: '토론토 좋은나무연합교회',
        url: 'https://www.facebook.com/groups/torontogoodtreechurch/',
        src:
          'https://static.wixstatic.com/media/51f166_81460567a3534ab186a8f67cb3e8f59b.jpg/v1/fill/w_600,h_517,al_c,lg_1,q_80/51f166_81460567a3534ab186a8f67cb3e8f59b.webp',
        flex: 12,
      },
      {
        title: '무지개예수',
        url: 'http://www.rainbowyesu.org/?ckattempt=1',
        src:
          'https://drive.google.com/uc?export=view&id=1cu6o-3QeTQzpCEymMi8Wqq9iz001lPG_',
        flex: 12,
      },
    ],
  }),
};
</script>

<style>
span {
  font-weight: bold;
  color: #9575cd;
}
.circle-image {
  border-radius: 100%;
}
ul {
  list-style: none;
}
ul li::before {
  content: '\2022';
  color: #4caf50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
* {
  line-height: 2;
}
</style>
